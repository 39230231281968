import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import {
    fetchCommunity,
} from "../../helpers/databaseHelpers";

let Nri = () => {
   let baseUrl = process.env.REACT_APP_BACKEND_URL;

   document.getElementsByTagName('title').innerText = `NRI - First Matrimonial Website for Satsangi Rishtey`;

   const [error, setError] = useState("");
   const [communityes, setCommunityes] = useState([]);
   
   let [Looking_for, SetLooking_for] = useState('woman');
   let [Start_age, SetStart_age] = useState('18');
   let [End_age, SetEnd_age] = useState('27');
   let [login_btn, Setlogin_btn] = useState(0);
   
   useEffect(() => {
      const loadCommunity = async () => {
          try {
              const communityData = await fetchCommunity();
              const communityess = [];
              communityData.map((comu) => {
                  communityess.push(comu.name);
              });
              setCommunityes(communityess);
          } catch (error) {
              console.error("Error loading community:", error);
              setError("Failed to load community");
          }
      };

      loadCommunity();
  }, []);

  const [nri_community, SetNriCommunity] = useState('');
  const [nri_name,setNriName] = useState("");
  const [nri_phone,setNriPhone] = useState("");
  const [nri_message,setNriMessage] = useState("");

  const [nri_name_err,setNriNameErr] = useState("");
  const [nri_phone_err,setNriPhoneErr] = useState("");
  const [nri_community_err,setNriCommunityErr] = useState("");
  const [nri_messageErr,setNriMessageErr] = useState("");




   function handelFoucs(id) {
      document.getElementById(id).classList.add('drop_bx_active');
   }

   function handelFoucsout(id) {
      let el = document.getElementById(id);
      if (el) {
         setTimeout(() => {
            document.getElementById(id).classList.remove('drop_bx_active');
         }, 300);
      }
   }



setTimeout(() => {
   document.getElementsByTagName('nav')[0].classList.add('active_vip_nav');
}, 1000);
   

function NriValid(){
   let valid1 = true;
   if(nri_name==''){
      setNriNameErr('Name is Required!');
       valid1 = false;
   }

   if(nri_phone==''){
      setNriPhoneErr("Phone is Required!");
       valid1 = false;
   }

   if(nri_message==""){
       setNriMessageErr("Message is Required!");
       valid1 = false;
   }
   if(nri_community==''){
      setNriCommunityErr("Spritual Path is Required!");
       valid1 = false;
   }

   return valid1;
}


const handleNriSubmit = async (event) =>{
   event.preventDefault();
   if(NriValid()){
     
       const formData = new FormData();
       formData.append("name", nri_name);
       formData.append("spritual_path", nri_community);
       formData.append("phone", nri_phone);
       formData.append("type", "nri");
       formData.append("message", nri_message);
       try {
           const response = await fetch(`${baseUrl}/enquiry`, {
             method: "POST",
             body: formData,
           });
   
           const data = await response.json();
           if (response.ok) {
            toastr.options.escapeHtml = false; 
             toastr.success(data.message);
             setNriMessage("");
             setNriName("");
             setNriPhone("");
             SetNriCommunity("");
            
           } else {
             toastr.error(data.message);
           }
         } catch (error) {
           toastr.error("An error occurred. Please try again later.");
         }
   }

}

   return (
      <>
         <header className='fccf-display nri_page'>
            <div className="content fccf-display">
               <h1>NRI & VIP Rishtey</h1>
            </div>
         </header>


         <div className="w100 m_auto  nri_tags nri_tags_first">
            <h4 className='center'>BROWSE</h4>
            <h1 className='head center'><span>Matrimonial</span> Profiles By</h1>
            <ul className="w100 fcs-display"><li className="fw5">Brahma Kumaris</li><h5></h5><li className="fw5">Divya Jyoti Jagrati Sansthan</li><h5></h5><li className="fw5">Dera Sacha Sauda, Sirsa</li><h5></h5><li className="fw5">Guruji</li><h5></h5><li className="fw5">Jagat Guru Rampal Ji</li><h5></h5><li className="fw5">Manav Utthan Sewa Samiti</li><br /><li className="fw5">Radha Saomi Satsang, Beas</li><h5></h5><li className="fw5">Radhasoami Satsang, Dayalbagh</li><h5></h5><li className="fw5">Radha Swami Satsang Dinod</li><h5></h5><li className="fw5">Sachkhand Nanak Dham, Loni</li><h5></h5><li className="fw5">Sadhguru</li><br /><li className="fw5">Sant Asharamji Bapuji</li><h5></h5><li className="fw5">Sant Nirankari</li><h5></h5><li className="fw5">Sri Sri Ravi Shankar</li><h5></h5><li className="fw5">Sawan Kirpal Ruhani Mission</li><h5></h5><li className="fw5">Other</li></ul>
         </div>



         <div className="register_band w80 fcs-display">
            <h1 className='head'>You have the choice of keeping your profile info 100% confidential and discreet.</h1>
            <button className='btn_reset'><i className="bi bi-shield-lock-fill" style={{ marginRight: '5px' }}></i> 100% Privacy</button>
         </div>


   

         <section className='why signup_process w70 m_auto'>
            <h1 className='head center'>Rishtey from All <span>Across the World</span> </h1>
         </section>


         <div className="w70 m_auto fcc-display nri_tags" style={{marginBottom :'-18px'}}>
            {/* <h1 className='head center' style={{ marginTop: '10px' }}>Rishtey from  <span>All Across the World</span></h1> */}
            <ul className='w100 fcs-display'>
               <li className='fw5'>India</li>
               <h5></h5>
               <li className='fw5'>USA</li>
               <h5></h5>
               <li className='fw5'>Canada</li>
               <h5></h5>
               <li className='fw5'>UK</li>
               <h5></h5>
               <li className='fw5'>Signapore</li>
               <h5></h5>
               <li className='fw5'>Australia</li>
               <h5></h5>
               <li className='fw5'>UAE</li>
               <h5></h5>
               <li className='fw5'><Link to='#'>NRI Matrimonials <i className="bi bi-caret-right-fill"></i></Link></li>
            </ul>
         </div>

         <section className='contactus w55 fcc-display m_auto'>

            <h1 className='head center'>Please fill this form and <span>our counsellor
               will contact you back:</span> </h1>

            <div className="right_job_bx w50 fcc-display" style={{ margin: 'auto' }}>
               <img src="img/couple_vip.png" className='w100' />
            </div>
            <div className="left_job_bx w50">
               <form action="javascript:void(0)" method="post" id="contactForm" onSubmit={handleNriSubmit}>
               <div className="card_bx">
                        <label htmlFor="First_Name">
                            <i className="bi bi-person-fill"></i>Name
                        </label>
                        <input
                            type="text"
                            placeholder="Name"
                            name="Name"
                            id="nri_name" 
                            value={nri_name} onChange={(e)=>{setNriName(e.target.value);setNriNameErr("");}}
                        />
                         <div className="err">
                            {nri_name_err}
                         </div>
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-phone-fill"></i>Phone
                        </label>
                        <input
                            type="text"
                            placeholder="Phone"
                            name="Phone"
                            id="nri_phone" 
                            value={nri_phone} onChange={(e)=>{setNriPhone(e.target.value);setNriPhoneErr("");}}
                        />

                        <div className="err">
                            {nri_phone_err}
                         </div>
                         
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-chat-dots-fill"></i>Message
                        </label>
                        <textarea name="message" id="" placeholder="Message" onChange={(e)=>{setNriMessage(e.target.value);setNriMessageErr("")}} value={nri_message}></textarea>
                        <div className="err">
                            {nri_messageErr}
                        </div>
                    </div>

                  <div className="filter_card" >
                        <label htmlFor="Looking For a">
                            <i className="bi bi-plus-circle-fill"></i> Spritual Path
                        </label>
                        <br />
                        <input
                            type="text"
                            placeholder="Select"
                            value={nri_community}
                            name="job_community" 
                            id="job_community" 
                            onFocus={() => handelFoucs("follower_of_drop_bx2")}
                            onBlur={() => handelFoucsout("follower_of_drop_bx2")} onChange={() => { }}
                        />
                        <i className="bi bi-caret-down-fill"></i>
                        <div className="drop_bx" id="follower_of_drop_bx2">
                            {communityes.map((el, i) => (
                                <li key={i} onClick={() => { SetNriCommunity(el); setNriCommunityErr(""); }}>
                                 {el}
                                </li>
                            ))}
                        </div>
                        <div className="text-danger community_err err">{nri_community_err}</div>
                    </div>
                  <div className="card_bx">
                     <button className="reset_btn" type="submit">Submit</button>
                  </div>
               </form>
            </div>

         </section>
      </>
   )
}

export default Nri;