import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { fetchData, updateData } from "../../helpers/databaseHelpers";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const MyPhoto = () =>
{
    let baseUrl = process.env.REACT_APP_BACKEND_URL;
    const [ userEdit, setUserEdit ] = useState( false );
    const token = localStorage.getItem( "authToken" );
    let navigate = useNavigate();

    if ( !token )
    {
        console.log( "No token found. Redirecting to login." );
        navigate( "/" );
    }

    const [ images, setImages ] = useState( [] );
    const [ images_count_u, setImages_count_u ] = useState( 0 );
    const [ userDetails, setUserDetails ] = useState( {} );
    const [ visibility, setVisibility ] = useState( "" );

    useEffect( () =>
    {
        const loadDetails = async () =>
        {
            try
            {
                const whereObj = { token: token };
                const userData = await fetchData( "*", "users", whereObj );
                setUserDetails( userData[ 0 ] );
            } catch ( error )
            {
                console.error( "Error loading user details:", error );
            }
        };
        loadDetails();
    }, [ token ] );

    useEffect( () =>
    {
        const loadImageList = async () =>
        {
            if ( userDetails.uniquecode )
            {
                try
                {
                    const listData = await fetchData( "*", "user_image_gallery", { status: 'active', user_id: userDetails.uniquecode } );
                    setImages( listData );
                    setVisibility( userDetails.images_visibility );
                } catch ( error )
                {
                    console.error( "Error loading images:", error );
                }
            }
        };
        loadImageList();
    }, [ userDetails.uniquecode, userEdit, userDetails.images_visibility , images_count_u ] );

    const handleChange = ( event, user_id ) =>
    {
        const value = event.target.value;
        setVisibility( value );
        changeVisibility( value, user_id );
    };

    const changeVisibility = async ( visibility, user_id ) =>
    {
        try
        {
            const whereObjA = { user_id: user_id };
            const dataArr = { visibility: visibility };
            await updateData( "user_image_gallery", dataArr, whereObjA );
            await updateData( "users", { images_visibility: visibility }, { uniquecode: user_id } );
            toastr.success( "Visibility Changed" );
        } catch ( error )
        {
            console.error( "Error changing visibility:", error );
        }
    };

    const handleImageChange = async ( event ) =>
    {
        setUserEdit( false );

        const file = event.target.files[ 0 ];
        event.preventDefault();

        // Check if a file was selected
        if ( !file )
        {
            toastr.error( "No file selected." );
            setUserEdit( true );
            return;
        }

        // Validate file size (3MB = 3 * 1024 * 1024 bytes)
        const maxSize = 3 * 1024 * 1024;
        if ( file.size > maxSize )
        {
            toastr.error( "File size exceeds 3MB. Please choose a smaller file." );
            setUserEdit( true );
            return;
        }

        document.getElementById( 'uploadBtn' ).innerText = 'Uploading...';

        const formData = new FormData();
        formData.append( "photo", file );
        formData.append( "uniquecode", userDetails.uniquecode );
        formData.append( "visibility", userDetails.images_visibility );

        try
        {
            const response = await fetch( `${ baseUrl }/insertUserPics`, {
                method: "POST",
                body: formData,
            } );
            const data = await response.json();
            if ( data.status )
            {
                toastr.success( data.message );
                document.getElementById( 'uploadBtn' ).innerText = 'Upload Photo';
                setUserEdit( true );
            } else
            {
                toastr.error( "Error in uploading image!" );
                setUserEdit( true );
            }
        } catch ( error )
        {
            toastr.error( "Error in uploading image!" );
            setUserEdit( true );
        }
    };


    let delteImage = async ( e, id, i ) =>
    {
        
        try
        {

            const response = await fetch( `${ baseUrl }/imageDelete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify( { id } )
            } );
            if ( !response.ok )
            {
                throw new Error( 'Network response was not ok' );
            }
            const data = await response.json();
            if (data) {
                setImages_count_u(id);
            }
        } catch ( error )
        {
            console.error( 'Failed to fetch data:', error );
            throw error;
        }
    }

    return (
        <>
            <section className="w70 m_auto myphoto">
                <div className="left_bx">
                    <h3>Quick Links</h3>
                    <div className="links_myphoto">
                        <Link to='/shortlisted'>- Shortlists & more</Link>
                        <Link to='/newmatches'>- New Matches</Link>
                        <Link to='/mymatches'>- My Matches</Link>
                        <Link to='/received'>- Received Invitations</Link>
                        <Link to='/setting'>- My Help</Link>
                    </div>
                </div>
                <div className="right_bx">
                    <h1>My Photo</h1>

                    <div className="upload_photo">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={ handleImageChange }
                            name="photo"
                        />
                        <p>Upload photos from your computer</p>
                        <button onClick={ handleImageChange } id="uploadBtn">Upload Photo</button>
                    </div>

                    <p className="pra">To get more responses on your profile Upload at least 15-20 photos</p>

                    <div className="recent">
                        <div className="head fcs-display w100">
                            <h3>Upload Photo ({ images.length })</h3>
                            <div className="scroll_btn">
                                <i className="bi bi-caret-left-fill" onClick={ () =>
                                {
                                    document.getElementsByClassName(
                                        "recent_cards"
                                    )[ 0 ].scrollLeft -=
                                        document.getElementsByClassName( "card-container" )[ 0 ]
                                            .clientWidth + 15;
                                } }></i>
                                <i className="bi bi-caret-right-fill" onClick={ () =>
                                {
                                    document.getElementsByClassName(
                                        "recent_cards"
                                    )[ 0 ].scrollLeft +=
                                        document.getElementsByClassName( "card-container" )[ 0 ]
                                            .clientWidth + 15;
                                } }></i>
                            </div>
                        </div>
                        <div className="recent_cards fcs-display" style={ { scrollBehavior: 'smooth' , justifyContent: 'unset' } }>
                            { images.length > 0 ? (
                                images.map( ( el, i ) => (
                                    <div className="card-container" key={ i } style={ { position: 'relative' } }>
                                        <i className="bi bi-trash3-fill delete_icon" onClick={ ( e ) => delteImage( e, el.id, i ) } ></i>
                                        <img src={ el.image_url } alt="" id={ `image${ i }` } />
                                    </div>
                                ) )
                            ) : (
                                <p>No photos found.</p>
                            ) }
                        </div>
                    </div>

                    <p className="pra2">
                        You can also send your photos to "<a href='mailto:contact@satsangirishtey.com'>contact@satsangirishtey.com</a>" with your account ID to upload the photos.
                    </p>

                    <div className="radio_btns">
                        <h4>Choose Photo View Options</h4>
                        <div className="card">
                            <input
                                type="radio"
                                id="all1"
                                name="visibility"
                                value="all"
                                checked={ visibility === 'all' }
                                onChange={ ( event ) => handleChange( event, userDetails.uniquecode ) }
                            />
                            <label htmlFor="all1">Visible to all members</label>
                        </div>

                        <div className="card">
                            <input
                                type="radio"
                                id="connections1"
                                name="visibility"
                                value="my_connection"
                                checked={ visibility === 'my_connection' }
                                onChange={ ( event ) => handleChange( event, userDetails.uniquecode ) }
                            />
                            <label htmlFor="connections1">Visible only to my connections</label>
                        </div>

                        <div className="card">
                            <input
                                type="radio"
                                id="pre_connections1"
                                name="visibility"
                                value="pro"
                                checked={ visibility === 'pro' }
                                onChange={ ( event ) => handleChange( event, userDetails.uniquecode ) }
                            />
                            <label htmlFor="pre_connections1">Visible only to premium members</label>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MyPhoto;
