import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import {
    fetchData,
  } from "../../helpers/databaseHelpers";

let Plan = () => {
    let baseUrl = process.env.REACT_APP_BACKEND_URL;

    let navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    
    if (!token) {
      console.log("No token found. Redirecting to login.");
      navigate("/");
    }

    const [plans, setPlan] = useState([]);
    useEffect(() => {
        const loadPlan = async () => {
            try {
                const whereObjj = { 'status':'active'};
                const meData = await fetchData("*", "plan", whereObjj);
                setPlan(meData);
            } catch (error) {
                console.error("Error loading plan details:", error);
            }
        };
        loadPlan();
    }, [token]);


   
   
    return (
        <>
            <section className="w90 contactus fcs-display m_auto">
                <div className="pricing" id="pricing" style={{marginTop: '-35px'}}>
                    <div className="head">
                        <h6>Increase your chances of matchmaking</h6>
                        <h3>Subscription Plans</h3>
                        <div className="spans" style={{marginTop: '10px'}}>
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                    <div className="cards">
                    { plans.length > 0 ? (
                  plans.map((el, i) => (
                    <div className="card">
                    <h2>₹{el.price} <span><del>₹{el.cross_price}</del></span></h2>
                    <h5>{el.name} </h5>
                    
                    <ul>
                        <li><span>{el.no_of_contact}</span> Contact Us</li>
                        <li>{el.primium_badge =='yes' ? ( <span className="span_yes"><i className="bi bi-check-lg"></i></span>) : (<span className="span_x">x</span>) } Premium Badge</li>
                        <li>{el.relationship_manager =='yes' ? ( <span className="span_yes"><i className="bi bi-check-lg"></i></span>) : (<span className="span_x">x</span>) } Relationship Manager</li>
                        <li>{el.nri_rishtey =='yes' ? ( <span className="span_yes"><i className="bi bi-check-lg"></i></span>) : (<span className="span_x">x</span>) } Nri Rishtey</li>
                        <li>{el.vip_treatment =='yes' ? ( <span className="span_yes"><i className="bi bi-check-lg"></i></span>) : (<span className="span_x">x</span>) } VIP Treatment</li>
                    </ul>
                    <button><Link to={`/paynow/${el.price}`}>Choose Plan</Link></button>
                    <h6>{el.banner}</h6>
                </div>
                    ))
                    ) : (
                    <p>No plans found.</p>
                    )}
                       
                    </div>
                </div>
            </section>
        </>
    );
};

export default Plan;
