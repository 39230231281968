import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { fetchData } from "../../helpers/databaseHelpers";
import '../../Website/assets/css/pay.css';

const Paynow = () => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    const { amount } = useParams();
    const [plan, setPlan] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        const loadDetails = async () => {
            try {
                const userData = await fetchData("*", "users", { token });
                if (!userData.length) {
                    navigate('/');
                } else {
                    const user = userData[0];
                    setUserDetails(user);
                    setName(`${user.first_name} ${user.last_name}`);
                    setNumber(user.phone);
                }
            } catch (error) {
                console.error("Error loading user details:", error);
                navigate('/');
            }
        };

        const loadPlan = async () => {
            try {
                const planData = await fetchData("*", "plan", { status: "active", price: amount });
                if (planData.length) {
                    setPlan(planData[0]);
                }
            } catch (error) {
                console.error("Error loading plan details:", error);
            }
        };

        loadDetails();
        loadPlan();
    }, [token, amount, navigate]);

    const gstRate = 18;
    const gstAmount = ((amount * gstRate) / 100).toFixed(2);
    const totalAmount = (parseFloat(amount) + parseFloat(gstAmount)).toFixed(2);

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Name is required!';
        if (!number) newErrors.number = 'Phone is required!';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePayment = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append("name", name);
        formData.append("user_id", userDetails.uniquecode);
        formData.append("mobileNumber", number);
        formData.append("amount", totalAmount);
        formData.append("plan", plan.name);
        formData.append("no_of_contact", plan.no_of_contact);
        formData.append("amount_before_gst", plan.price);
        formData.append("gst_amount", gstAmount);

        try {
            console.log('base_url', `${baseUrl}/phonepayPayment`);
            const response = await fetch(`${baseUrl}/phonepayPayment`, {
                method: "POST",
                body: formData,
            });

            const data = await response.json();
            if (response.ok) {
                console.log('data', data.data.data.instrumentResponse.redirectInfo.url);
                window.location.href = data.data.data.instrumentResponse.redirectInfo.url;
            } else {
                toastr.error(data.message || 'Failed to initiate payment.');
            }
        } catch (error) {
            console.error("Error initiating payment:", error);
            toastr.error("An error occurred. Please try again later.");
        }
    };

    return (
        <section className="w90 contactus paynow fcs-display m_auto">
            <div className="details">
                <div className="detail-row">
                    <span className="label">Amount:</span>
                    <span className="value">&#8377;{amount}</span>
                </div>
                <div className="detail-row">
                    <span className="label">Contact:</span>
                    <span className="value">{plan.no_of_contact}</span>
                </div>
                <hr className="divider" />
                <div className="detail-row total">
                    <span className="label"></span>
                    <span className="value">{plan.name}</span>
                </div>
            </div>
            <div className="w50">
                <form onSubmit={handlePayment}>
                    <div className="card_bx">
                        <label htmlFor="Name">
                            <i className="bi bi-person-fill"></i>Name
                        </label>
                        <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrors({ ...errors, name: "" });
                            }}
                        />
                        <div className="err">{errors.name}</div>
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-phone-fill"></i>Number
                        </label>
                        <input
                            type="text"
                            placeholder="Phone"
                            value={number}
                            onChange={(e) => {
                                setNumber(e.target.value);
                                setErrors({ ...errors, number: "" });
                            }}
                        />
                        <div className="err">{errors.number}</div>
                    </div>
                    <div className="card_bx">
                        <button className="pay_now" type="submit">
                            Pay Now
                        </button>
                    </div>
                </form>
            </div>
            <div className="right_job_bx w50 fcc-display" style={{ margin: "auto" }}>
                <img src="icon/illu_8.png" alt="" style={{ width: "80%" }} />
            </div>
        </section>
    );
};

export default Paynow;
