import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import
{
    fetchData,
} from "../../helpers/databaseHelpers";

let User = () =>
{
    let baseUrl = process.env.REACT_APP_BACKEND_URL;
    let navigate = useNavigate();
    const token = localStorage.getItem( "authToken" );

    if ( !token )
    {
        console.log( "No token found. Redirecting to login." );
        navigate( "/" );
    }

    const [ me, setMe ] = useState( [] );
    useEffect( () =>
    {
        const loadMe = async () =>
        {
            try
            {
                const whereObjj = { token };
                const meData = await fetchData( "*", "users", whereObjj );
                setMe( meData[ 0 ] );
            } catch ( error )
            {
                console.error( "Error loading user details:", error );
            }
        };
        loadMe();
    }, [ token ] );

    const { uniquecode } = useParams();
    const [ userDetails, setUserDetails ] = useState( [] );

    useEffect( () =>
    {
        const loadDetails = async () =>
        {
            try
            {
                const whereObj = { uniquecode };
                const userData = await fetchData( "*", "users", whereObj );
                setUserDetails( userData[ 0 ] );
            } catch ( error )
            {
                console.error( "Error loading user details:", error );
            }
        };
        loadDetails();
    }, [ uniquecode ] );

    const copyToClipboard = ( text ) =>
    {
        navigator.clipboard.writeText( text ).then( () =>
        {
            document.getElementById( 'copyIcon' ).classList.remove( 'bi-clipboard-fill' );
            document.getElementById( 'copyIcon' ).classList.add( 'bi-clipboard-check-fill' );
            setTimeout( () =>
            {
                document.getElementById( 'copyIcon' ).classList.add( 'bi-clipboard-fill' );
                document.getElementById( 'copyIcon' ).classList.remove( 'bi-clipboard-check-fill' );

            }, 2000 );
        } ).catch( err =>
        {
            console.error( 'Failed to copy text: ', err );
        } );
    };


    const handleClick = () =>
    {
        const textToCopy = document.getElementById( 'copyText' ).innerText;
        copyToClipboard( textToCopy );
    };



    const calculateAge = ( dateOfBirth ) =>
    {
        const today = new Date();
        const birthDate = new Date( dateOfBirth );
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if ( monthDifference < 0 || ( monthDifference === 0 && today.getDate() < birthDate.getDate() ) )
        {
            age--;
        }

        return age;
    };


    let [ newUser, setNewUser ] = useState( [] );

    useEffect( () =>
    {
        let load = async () =>
        {
            await fetch( `${ baseUrl }/findUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { 'user2': uniquecode, 'user': me.uniquecode } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setNewUser( data.data );
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        load();
    }, [ me ] )


    // Add Fav/Rem 

    let favRem = async ( vid, type, el ) =>
    {
        {
            await fetch( `${ baseUrl }/addFavRem`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': me.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if ( data.data == 'add' )
                    {
                        el.setAttribute( 'value', 'yes' );
                        el.style.color = 'red';
                        el.style.textShadow = '0px 5px 10px rgb(251 0 10)';
                    } else
                    {
                        el.setAttribute( 'value', 'no' );
                        el.style.color = '#f6aab2';
                        el.style.textShadow = '0px 5px 10px rgb(209, 134, 137)';
                    }
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }

    // Get Connect  
    let [ connectList, setConnectList ] = useState( [] );

    // Get Connect Data

    useEffect( () =>
    {
        let load = async () =>
        {
            await fetch( `${ baseUrl }/getConnectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { 'uid': me.uniquecode } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setConnectList( data.data );
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        load();
    }, [ me ] )

    let checkConnectUser = ( value ) =>
    {
        let newData = connectList.filter( ( el, i ) => { return el.visitor_id == value } );
        return newData.length;
    }


    // Connect Now
    let connectUser = async ( vid, type, up, cu ) =>
    {
        {
            await fetch( `${ baseUrl }/connectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': me.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if ( data.data == 'add' )
                    {
                        document.getElementById( cu ).style.display = 'none';
                        document.getElementById( up ).style.display = 'flex';
                    }
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }


    // Get Partner Perfercese  
    let [ partner_preferences, setPartnerPreference ] = useState( [] );

    // Get Partner Preferences Data

    useEffect( () =>
    {
        const loadpref = async () =>
        {
            try
            {
                if ( me.uniquecode )
                {
                    let user_id = me.uniquecode;
                    const whereObj1 = { user_id: user_id };
                    console.log( whereObj1 )
                    const pref = await fetchData( "*", "partner_preferences", whereObj1 );
                    if ( pref )
                    {
                        setPartnerPreference( pref[ 0 ] );
                    }

                    console.log( 'np', pref );
                }
            } catch ( error )
            {
                console.error( "Error loading preferences:", error );
            }
        };
        loadpref();


    }, [ me ] )


    let [ partner_match_count, setPartnerMatchCount ] = useState( 0 );

    useEffect( () =>
    {
        const load = () =>
        {
            if ( !newUser || !newUser[ 0 ] ) return; // Ensure newUser[0] exists

            const user = newUser[ 0 ];
            let matchCount = 0;

            if ( partner_preferences.start_age <= user.age && partner_preferences.end_age >= user.age )
            {
                matchCount = 1;
            }
            if (
                partner_preferences.start_height_inch <= user.height_in_inch &&
                partner_preferences.end_height_inch >= user.height_in_inch
            )
            {
                matchCount = 2;
            }
            if (
                ( partner_preferences?.religion?.split( ',' ) || [] ).some( el => el.trim() === user.community )
            )
            {
                matchCount = 3;
            }
            if (
                ( partner_preferences?.mother_tongue?.split( ',' ) || [] ).some( el => el.trim() === user.mother_tongue )
            )
            {
                matchCount = 4;
            }
            if (
                ( partner_preferences?.country?.split( ',' ) || [] ).some( el => el.trim() === user.country ) &&
                ( partner_preferences?.state?.split( ',' ) || [] ).some( el => el.trim() === user.state )
            )
            {
                matchCount = 5;
            }

            setPartnerMatchCount( matchCount );
        };

        if ( partner_preferences && newUser && newUser[ 0 ] )
        {
            load();
        }
    }, [ partner_preferences, newUser ] );

    // Recent Visiter

    useEffect( () =>
    {

        let recentVisiter = async ( vid ) =>
        {
            {
                await fetch( `${ baseUrl }/recentVisiter`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify( { vid, 'uid': me.uniquecode } ),
                } ).then( response =>
                {
                    if ( !response.ok )
                    {
                        throw new Error( "Network response was not ok" );
                    }
                    return response.json();
                } )
                    .then( data =>
                    {

                    } )
                    .catch( error =>
                    {
                        console.error( "There was a problem with the fetch operation:", error );
                    } );
            }
        }


        if ( Array.isArray( newUser ) && newUser.length > 0 )
        {
            recentVisiter( newUser[ 0 ].uniquecode )
        }

    }, [ newUser ] )

    const [ imagesData, setImagesData ] = useState( [] );
    let [ slideImage, setSlideImage ] = useState( '' );
    let [ imgIndex, setImageIndex ] = useState( 0 );


    useEffect( () =>
    {
        const loadImageList = async () =>
        {
            if ( uniquecode )
            {
                try
                {
                    const listData = await fetchData( "*", "user_image_gallery", { status: 'active', user_id: uniquecode } );
                    setImagesData( listData );
                    if (listData.length != 0) {
                        setSlideImage( listData[ 0 ].image_url)
                    }
                } catch ( error )
                {
                    console.error( "Error loading images:", error );
                }
            }
        };
        loadImageList();
    }, [ uniquecode ] );


    let [ random5Profile, setRandow5Profile ] = useState( [] );

    useEffect( () =>
    {
        let getRandom5Profile = async ( vid ) =>
        {
            {
                await fetch( `${ baseUrl }/getRandomProfile`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify( { community: userDetails.community , uniquecode } ),
                } ).then( response =>
                {
                    if ( !response.ok )
                    {
                        throw new Error( "Network response was not ok" );
                    }
                    return response.json();
                } )
                    .then( data =>
                    {

                        setRandow5Profile( data.data );
                    } )
                    .catch( error =>
                    {
                        console.error( "There was a problem with the fetch operation:", error );
                    } );
            }
        }

        if ( userDetails )
        {
            getRandom5Profile()
        }
    }, [ userDetails ] )


    return (
        <>
            {
                Array.isArray( newUser ) && newUser.length > 0 ? (
                    <>
                        <div className="mainSectionProfile">
                            <div className="imageShopPopup">
                                <div className="left_right_btn fcs-display">
                                    <i
                                        className="bi bi-arrow-left-circle-fill"
                                        onClick={ () =>
                                        {
                                            setImageIndex( ( prev ) => ( imgIndex !== 0 ? prev - 1 : imagesData.length - 1 ) );
                                        } }
                                    ></i>
                                    <i
                                        className="bi bi-arrow-right-circle-fill"
                                        onClick={ () =>
                                        {
                                            setImageIndex( ( prev ) => ( imgIndex !== imagesData.length - 1 ? prev + 1 : 0 ) );
                                        } }
                                    ></i>
                                    <i className="bi bi-x-circle-fill clickToOpenImges clickToOpenImges2" onClick={ () => { document.getElementsByClassName( 'imageShopPopup' )[ 0 ].classList.remove( 'imageShopPopup_active' ) } }></i>
                                </div>
                                <div className="showUserImages">
                                    <img src={ imagesData.length != 0 ? imagesData[ imgIndex ].image_url : '' } alt="User Display" />
                                </div>
                            </div>
                            <section className="w55 new_matches user_details fcs-display m_auto" >

                                <div className="match_right_bx " >
                                    <div className="card_matches w-100 fcs-display">
                                        <div className="img_bx">
                                            { imagesData.length != 0 ? <i className="bi bi-images clickToOpenImges" onClick={ () => { document.getElementsByClassName( 'imageShopPopup' )[ 0 ].classList.add( 'imageShopPopup_active' ) } }></i> : '' }
                                            <img src={ newUser[ 0 ].profile_pic } alt="User Profile" onError={ ( e ) => { e.target.onerror = null; e.target.src = 'https://svgsilh.com/png-512/659651.png'; } } />
                                        </div>
                                        <div className="content_bx">
                                            <h4>{ newUser[ 0 ].first_name } { newUser[ 0 ].last_name } <i className="bi bi-shield-check"></i></h4>
                                            <div className="cont_head w-100 fcs-display">
                                                <h6>{ newUser[ 0 ].community }</h6>
                                                <div className="r_fav">
                                                    <span style={ { marginRight: '50px', position: 'relative', top: '-15px' } }>Online 3h ago</span>
                                                    <i className="bi bi-heart-fill" value={ newUser[ 0 ].is_match == 'yes' ? 'yes' : 'no' } style={ newUser[ 0 ].is_match == 'yes' ? { color: 'red', textShadow: '0px 5px 10px rgb(251 0 10)' } : { color: '#f6aab2', textShadow: ' 0px 5px 10px rgb(209, 134, 137)' } } onClick={ ( e ) => { e.target.getAttribute( 'value' ) == 'no' ? favRem( newUser[ 0 ].uniquecode, 'add', e.target ) : favRem( newUser[ 0 ].uniquecode, 'rem', e.target ) } } ></i>
                                                </div>
                                            </div>

                                            <ul className="fcs-display w-100">
                                                <li>{ ( newUser[ 0 ].age ) ? newUser[ 0 ].age : calculateAge( newUser[ 0 ].d_o_b ) } yrs, { newUser[ 0 ].height }</li>
                                                <li>{ newUser[ 0 ].marital_status }</li>
                                                <li>{ newUser[ 0 ].mother_tongue }</li>
                                                <li>{ newUser[ 0 ].country }, { newUser[ 0 ].state }</li>
                                                <li>{ newUser[ 0 ].community }</li>
                                                <li>{ newUser[ 0 ].religion }</li>
                                                <li>{ newUser[ 0 ].designation }</li>
                                                <li>{ newUser[ 0 ].qualification }</li>
                                            </ul>

                                        </div>
                                        <div className="connect_bx">
                                            { checkConnectUser( newUser[ 0 ].uniquecode ) == 0 ?
                                                <>
                                                    <div className="connect_profile w-100 fcc-display" id="connect_bx_new" >
                                                        <h6>Like this profile ?</h6>
                                                        <i className="bi bi-check-circle-fill" onClick={ ( e ) => { connectUser( newUser[ 0 ].uniquecode, 'add', `upgrade5`, `connect_bx_new` ) } }></i>
                                                        <p>Connect Now</p>
                                                    </div>

                                                    <div className="connected_profile w-100 fcc-display" id="upgrade5" style={ { display: 'none' } } >
                                                        <p><Link to='/plan'>Upgrade</Link>to
                                                            Contact her directly</p>
                                                        <button><i className="bi bi-telephone-fill"></i> Call</button>
                                                        <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                                        <button><i className="bi bi-chat-right-dots-fill"></i> Call</button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="connected_profile w-100 fcc-display" >
                                                        <p><Link to='/plan'>Upgrade</Link>to
                                                            Contact her directly</p>
                                                        <button><i className="bi bi-telephone-fill"></i> Call</button>
                                                        <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                                        <button><i className="bi bi-chat-right-dots-fill"></i> Call</button>
                                                    </div>
                                                </>

                                            }
                                        </div>
                                    </div>

                                </div>
                            </section>

                            <div className="deatils_user_bx w55 fcs-display m_auto">
                                <div className="left_bx">
                                    <h4>Similar Profiles</h4>

                                    {
                                        random5Profile.map( ( el, i ) => (
                                            <Link to={ `/user/${ el.uniquecode }` } className="randomProfiles" key={i} target="_blank">
                                                <div className="rmProfile">
                                                    <img src={ el.profile_pic } alt="" />
                                                    <div className="content">
                                                        <h5>{el.first_name} {el.last_name}</h5>
                                                        <h5>Age : {el.age} , Height : {el.height}</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        ) )
                                    }

                                    <Link to='/plan' className="ad_p"><img src="/img/premium_plan.jpg" alt="Plan" /></Link>
                                </div>
                                <div className="right_bx">
                                    <div className="id_btns fcs-display" style={ { paddingBottom: '15px', borderBottom: '1px solid #8080803d' } }>
                                        <div className="left_ids fcs-display">
                                            <button onClick={ handleClick }>ID:<span id="copyText">{ uniquecode }</span> <i id="copyIcon" className="bi bi-clipboard-fill"></i></button>
                                            <button>Profile creted by { newUser[ 0 ].profile_type }</button>
                                        </div>
                                        <div className="right_ids fcs-display">
                                            <i className="bi bi-share-fill" style={ { marginRight: '15px', cursor: 'pointer' } } onClick={ ( e ) =>
                                            {
                                                copyToClipboard( window.location.href ); e.target.style.color = '#a01c22'; setTimeout( () =>
                                                {
                                                    e.target.style.color = 'unset';
                                                }, 1000 );
                                            } }></i>
                                            <i className="bi bi-whatsapp" style={ { color: 'green', cursor: 'pointer' } } onClick={ ( e ) => { window.open( `https://wa.me/?text=${ window.location.href }`, '_blank' ) } }></i>
                                        </div>
                                    </div>

                                    <h3><img src="/icon/image00009.png" alt="" /> About { newUser[ 0 ].first_name } { newUser[ 0 ].last_name }</h3>
                                    <p>{ newUser[ 0 ].about }</p>
                                    <h3><img src="/icon/image00005.png" alt="" /> Contact Details</h3>
                                    <div className="contact_det "> {/*  use (contact_det_active) class */ }
                                        <label htmlFor="label"><i class="bi bi-telephone-fill"></i> Contact Number</label>
                                        <h4>{newUser[0].username.slice(0 , 4)}XXXXXX</h4>
                                        <label htmlFor="label"><i class="bi bi-envelope-fill"></i> Email ID</label>
                                        <h4> {newUser[0].email.slice(0, 2)}XXXX@gmail.com</h4>
                                        <i className="bi bi-lock-fill mail_icon"></i>
                                        <h4 className="contact_upgrade"><b><Link to={'/plan'}>Upgrade Now</Link></b> to view the details</h4>
                                    </div>
                                    <h3><img src="/icon/image00013.png" alt="" /> Religious Details</h3>

                                    <li><img src="/icon/image00007.png" alt="" /> Path : { newUser[ 0 ].community }</li>
                                    <li><img src="/icon/image00014.png" alt="" /> Religion :{ newUser[ 0 ].religion }</li>
                                    <li><img src="/icon/image00016.png" alt="" /> Initiated(Naamdan/Gyan) : { newUser[ 0 ].path_initiated ? newUser[ 0 ].path_initiated : 'No' }</li>
                                    <li><img src="/icon/image00002.png" alt="" /> Following the Path since birth? : { newUser[ 0 ].path_since ? newUser[ 0 ].path_since : 'No' }</li>
                                    <li><img src="/icon/image00002.png" alt="" />Are you an official Sevadar? : { newUser[ 0 ].official_sevadar ? newUser[ 0 ].official_sevadar : 'No' }</li>
                                    <li><img src="/icon/image00020.png" alt="" /> Diet : { newUser[ 0 ].diet }</li>
                                    {/* <li><img src="/icon/image00018.png" alt="" /> Satsangi in Family: Mother, Father, Sister</li> */ }


                                    <h3><img src="/icon/image00003.png" alt="" /> Fmaily Details</h3>

                                    <li><img src="/icon/image00012.png" alt="" /> Father: { newUser[ 0 ].father_ocupation != 'null' ? newUser[ 0 ].father_ocupation : '' }, Mother: { newUser[ 0 ].mother_occupation != 'null' ? newUser[ 0 ].mother_occupation : '' }</li>
                                    <li><img src="/icon/image00019.png" alt="" /> Siblings :  { newUser[ 0 ].no_of_brothers != 'null' ? newUser[ 0 ].no_of_brothers : '' } brother , { newUser[ 0 ].no_of_sisters != 'null' ? newUser[ 0 ].no_of_sisters : '' } sisters</li>
                                    <li><img src="/icon/image00017.png" alt="" /> Family Income: { newUser[ 0 ].family_financial_status != 'null' ? newUser[ 0 ].family_financial_status : '' }</li>
                                    <li><img src="/icon/image00006.png" alt="" /> Residential : { newUser[ 0 ].city } , { newUser[ 0 ].state } { newUser[ 0 ].country }</li>
                                    <li><img src="/icon/hometown.png" alt="" /> Hometown : { newUser[ 0 ].current_residence != 'null' ? newUser[ 0 ].current_residence : '' } </li>

                                    <h3><img src="/icon/image00004.png" alt="" /> Education Details</h3>

                                    <li><img src="/icon/image00004.png" alt="" /> Qualification : { newUser[ 0 ].qualification != 'null' ? newUser[ 0 ].qualification : '' }</li>
                                    <li><img src="/icon/image00009.png" alt="" /> Profession : { newUser[ 0 ].designation != 'null' ? newUser[ 0 ].designation : '' }</li>
                                    <li><img src="/icon/image00017.png" alt="" /> Income : { newUser[ 0 ].income != 'null' ? newUser[ 0 ].income : '' }</li>
                                    <li><img src="/icon/college.png" alt="" /> College Attended : { newUser[ 0 ].collage != 'null' ? newUser[ 0 ].collage : '' }</li>

                                    <h3><img src="/icon/image00008.png" alt="" /> Partner Preference</h3>

                                    <div className="partner_pre_user_bx  w100"> {/* hide deatils so add class (hide_pp_details) */ }
                                        <div className="both_user w80 m_auto fcs-display">
                                            <div className="user_card user_card12">
                                                <img src={ me.profile_pic } alt="" onError={ ( e ) => { e.target.onerror = null; e.target.src = 'https://svgsilh.com/png-512/659651.png'; } } />
                                                <h4>{ me.first_name } { me.last_name }</h4>
                                            </div>
                                            <div className="user_card">
                                                <h6>You match { partner_match_count }/5 of her preference</h6>
                                            </div>
                                            <div className="user_card user_card12">
                                                <img src={ newUser[ 0 ].profile_pic } alt="" onError={ ( e ) => { e.target.onerror = null; e.target.src = 'https://svgsilh.com/png-512/659651.png'; } } />
                                                <h4>{ newUser[ 0 ].first_name } { newUser[ 0 ].last_name }</h4>
                                            </div>
                                        </div>
                                        <div className="pp_details w100">
                                            <div className="pp_card">
                                                <div className="labels">
                                                    <h5>Age</h5>
                                                    <h6>{ partner_preferences.start_age } to { partner_preferences.end_age }</h6>
                                                </div>
                                                {
                                                    partner_preferences.start_age <= newUser[ 0 ].age && partner_preferences.end_age >= newUser[ 0 ].age ? (
                                                        <>
                                                            <i className="bi bi-check-circle-fill" ></i>

                                                        </>
                                                    ) : (
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    )
                                                }
                                            </div>
                                            <div className="pp_card">
                                                <div className="labels">
                                                    <h5>Height </h5>
                                                    <h6>{ partner_preferences.start_height_lable } ({ ( partner_preferences.start_height_inch ) * 2.54 } cm) to { partner_preferences.end_height_lable } ({ ( partner_preferences.end_height_inch ) * 2.54 } cm)</h6>
                                                </div>
                                                {
                                                    partner_preferences.start_height_inch <= newUser[ 0 ].height_in_inch && partner_preferences.end_height_inch >= newUser[ 0 ].height_in_inch ? (
                                                        <>
                                                            <i className="bi bi-check-circle-fill" ></i>

                                                        </>
                                                    ) : (
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    )
                                                }
                                            </div>
                                            <div className="pp_card">
                                                <div className="labels">
                                                    <h5>Spritiual Path</h5>
                                                    <h6>{ partner_preferences.community }</h6>
                                                </div>

                                                {
                                                    ( partner_preferences?.community?.split( ',' ) || [] ).some( el => el.trim() === newUser[ 0 ]?.community ) ? (
                                                        <>
                                                            <i className="bi bi-check-circle-fill" ></i>

                                                        </>
                                                    ) : (
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    )
                                                }


                                            </div>
                                            <div className="pp_card">
                                                <div className="labels">
                                                    <h5>Mother tongue:</h5>
                                                    <h6>{ partner_preferences.mother_tongue }</h6>
                                                </div>

                                                {
                                                    ( partner_preferences?.mother_tongue?.split( ',' ) || [] ).some( el => el.trim() === newUser[ 0 ]?.mother_tongue ) ? (
                                                        <>
                                                            <i className="bi bi-check-circle-fill" ></i>

                                                        </>
                                                    ) : (
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    )
                                                }

                                            </div>
                                            <div className="pp_card">
                                                <div className="labels">
                                                    <h5>Currently Living in</h5>
                                                    <h6>{ partner_preferences.country } / { partner_preferences.state }</h6>
                                                </div>

                                                {
                                                    ( partner_preferences?.country?.split( ',' ) || [] ).some( el => el.trim() === newUser[ 0 ]?.country ) && ( partner_preferences?.state?.split( ',' ) || [] ).some( el => el.trim() === newUser[ 0 ]?.state ) ? (
                                                        <>
                                                            <i className="bi bi-check-circle-fill" ></i>

                                                        </>

                                                    ) : (
                                                        <i className="bi bi-x-circle-fill"></i>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p>User Not Found</p>
                )
            }

        </>
    )
}

export default User;