import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const nav = document.getElementsByTagName('nav')[0];
    const logoImg = nav.getElementsByTagName('img')[0];
    nav.classList.add('active_nav');
    
    const handleScroll = () => {
      if (window.scrollY > 5) {
        if (location.pathname === '/') {
          logoImg.src = '/img/logo_wh.png';
          nav.classList.add('active_nav');
          nav.classList.add('active_nav1');
        } else {
          nav.classList.add('active_nav');
          logoImg.src = '/img/logo_wh.png';
        }
        
      } else {
        nav.classList.remove('active_nav');
        nav.classList.remove('active_nav1');
        if (location.pathname === '/') {
          logoImg.src = '/img/logo.png';
        } else {
          nav.classList.add('active_nav');
          logoImg.src = '/img/logo_wh.png';
        }
      }
    };

    handleScroll(); 

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (location.pathname === '/') {
        nav.classList.remove('active_nav');
        logoImg.src = 'img/logo.png';
      } else {
        nav.classList.add('active_nav');
        logoImg.src = 'img/logo_wh.png';
      }
    };
  }, [location.pathname]);

  return (
    <footer className="w100">
      <div className="w75 sub_footer fcs-display m_auto">
        <div className="foot_bx">
          <img src="/img/logo_wh.png" alt="Company Logo" />
          <p className="fw5">
          Satsangirishtey.com - India's first matrimonial website for "Satsangi Rishtey"
          Make your profile and search your life partner.
          </p>
        </div>
        <div className="foot_bx">
          <h5>Need Help?</h5>
          <ul>
            <li><Link to="#" onClick={() =>
              document
                .getElementsByClassName("container")[0]
                .classList.add("container_active")
            }>Member Login</Link></li>
            <li><Link to="#" onClick={() => {
                 document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.zIndex = 99999999999;
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.opacity = 1;
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" );
              
                }} >Sign Up</Link></li>
            <li><a href="/contactus" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Company</h5>
          <ul>
            <li><a href="/about-us" target="_blank" rel="noopener noreferrer">About Us</a></li>
            {/* <li><Link to="/plan">Plan</Link></li> */}
            <li><a href="/nri" target="_blank" rel="noopener noreferrer">VIP/NRI Shadi</a></li>
            <li><a href="#" target="_blank" rel="noopener noreferrer">Success Stories</a></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Privacy & You</h5>
          <ul>
            <li><a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            <li><a href="/terms-Of-use" target="_blank" rel="noopener noreferrer">Terms Of Use</a></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Follow Us</h5>
          <div className="social_media">
            {/* <Link to="https://www.facebook.com/satsangirishtey/" target="_blank"><i className="bi bi-facebook"></i></Link> */}
            <a href="https://www.facebook.com/satsangirishtey/" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
            {/* <Link to="https://www.youtube.com/@satsangirishtey" target="_blank"><i className="bi bi-youtube"></i></Link> */}
            <a href="https://www.youtube.com/@satsangirishtey" target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube"></i></a>
            {/* <Link to="https://www.instagram.com/satsangi_rishtey/" target="_blank"><i className="bi bi-instagram"></i></Link> */}
            <a href="https://www.instagram.com/satsangi_rishtey/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
            {/* <Link to="https://x.com/RishteySat65457" target="_blank"><i className="bi bi-twitter-x"></i></Link> */}
            <a href="https://x.com/RishteySat65457" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter-x"></i></a>
          </div>
          <ul>
            <li><a href="tel:0123456789" target="_blank"><i className="bi bi-phone-fill"></i> +91 8860020628</a></li>
            <li><a href="mailto:satsangirishtey@gmail.com" target="_blank"><i className="bi bi-envelope-fill"></i> satsangirishtey@gmail.com</a></li>
          </ul>
        </div>
      </div>
      <h6 className="m_auto center w75">Copyright 2023-2024 by satsangirishtey.com. All Rights Reserved. Powered by RSPH technology</h6>
    </footer>
  );
};

export default Footer;
