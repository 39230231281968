import React, { useEffect, useState } from "react";
import { fetchData, fetchVisitorCount } from "../../helpers/databaseHelpers";
import { useNavigate, Link } from "react-router-dom";
let Dashboard = () =>
{
  let baseUrl = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem( "authToken" );

  let navigate = useNavigate();
  if ( !token )
  {
    console.log( "No token found. Redirecting to login." );
    navigate( "/" );
  }

  let recent_arr = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ];

  const [ userDetails, setUserDetails ] = useState( [] );

  useEffect( () =>
  {
    const loadDetails = async () =>
    {
      try
      {
        const whereObj = { token: token };
        const userData = await fetchData( "*", "users", whereObj );
        if ( userData.length === 0 )
        {
          navigate( "/" );
        }
        setUserDetails( userData[ 0 ] );
      } catch ( error )
      {
        navigate( "/" );
      }
    };
    loadDetails();
  }, [ token ] );

  const [ visitorCount, setVisitorCount ] = useState( [] );
  useEffect( () =>
  {
    const loadVisitorCount = async ( user_id ) =>
    {
      try
      {
        const response = await fetchVisitorCount( user_id );
        setVisitorCount( response );
      } catch ( error )
      {
        console.log( "Error loading", error );
      }
    };
    loadVisitorCount( userDetails.uniquecode );
  }, [ userDetails.uniquecode ] );

  const getCountByActivityType = ( type ) =>
  {
    const activity = visitorCount.find( ( el ) => el.activity_type === type );
    return activity ? activity.total : 0;
  };

  //recent visitor


  const loadProfileData = async ( user_id, activity_type ) =>
  {
    try
    {
      const response = await fetch( `${ baseUrl }/getProfileData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { user_id, activity_type } ),
      } );

      const result = await response.json();

      if ( result.status )
      {
        return result.data;
      } else
      {
        console.log( "Error in fetching data" );
        return null;
      }
    } catch ( error )
    {
      console.error( "Fetch error:", error );
      return null;
    }
  };


  const [ RecentVisitors, setRecentVisitors ] = useState( [] );
  const [ RecentlyAdded, setRecentlyAdded ] = useState( [] );
  const [ NearMe, setNearMe ] = useState( [] );

  useEffect( () =>
  {
    const fetchProfileData = async () =>
    {
      if ( userDetails.uniquecode )
      {

        const data = await loadProfileData( userDetails.uniquecode, 'recent_visit' );
        if ( data )
        {
          setRecentVisitors( data );
        }

        const data1 = await loadProfileData( userDetails.uniquecode, 'accept_invite' );
        if ( data1 )
        {
          setRecentlyAdded( data1 );
        }
        const whereObject = { city: userDetails.city };
        const data2 = await fetchData( "*", "users", whereObject );
        if ( data2 )
        {
          setNearMe( data2 );
        }

      }
    };

    fetchProfileData();
  }, [ userDetails.uniquecode ] );


  // Recent Visiter

  let [ visterData, setVisiterData ] = useState( [] );

  useEffect( () =>
  {
    let recentVisiter = async ( vid, type, up, cu ) =>
    {
      {
        await fetch( `${ baseUrl }/getRecentVisiter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify( { user: userDetails.uniquecode } ),
        } ).then( response =>
        {
          if ( !response.ok )
          {
            throw new Error( "Network response was not ok" );
          }
          return response.json();
        } )
          .then( data =>
          {
            if ( data )
            {
              setVisiterData( data.data );
              console.log( data.data, visterData )
            }
          } )
          .catch( error =>
          {
            console.error( "There was a problem with the fetch operation:", error );
          } );
      }
    }

    if ( userDetails.uniquecode )
    {
      recentVisiter();
    }
  }, [ userDetails ] )


  let [ otpText, setOtpText ] = useState( '' );
  let [ otp, setOtp ] = useState( '' );
  let [ e_verify, setE_verify ] = useState( '' );

  const SendOtpViaMail = async ( email, phone ) =>
  {

    setOtpText( '' );

    fetch( `${ baseUrl }/sendOtpWithEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify( { email, phone } ),
    } ).then( response =>
    {
      if ( !response.ok )
      {
        throw new Error( "Network response was not ok" );
      }
      return response.json();
    } )
      .then( data =>
      {
        setOtpText( 'OTP sent successfully' );
        alert( 'OTP sent successfully' )
      } )

  };


  const VerifyOtp = async ( otp, email ) =>
  {

    if ( otp.length == 4 )
    {
      setOtpText( '' );

      fetch( `${ baseUrl }/OtpVerifyEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { otp, email } ),
      } ).then( response =>
      {
        if ( !response.ok )
        {
          throw new Error( "Network response was not ok" );
        }
        return response.json();
      } )
        .then( data =>
        {

          if ( data.data == 'success' )
          {
            fetch( `${ baseUrl }/UpdateEmailUserDetails`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify( { email } ),
            } ).then( response => response.json() )
              .then( result =>
              {
                setE_verify( 'yes' );
              } )
          } else
          {
            setOtpText( 'wrong' )
            setOtp( '' )
            alert( data.data )
          }
        } )
    }

  };



  return (
    <>
      <section className="w65 m_auto">
        <div className="profile_recent_etc w100 fcs-display">
          <div className="visiter_profile">
            {/* profile  */ }
            <div className="profile fcc-display w100">
              <div className="edit_img w100 fcc-display">
                <Link to='/myphoto'><img src={ userDetails.profile_pic } alt="" /></Link>
                {/* <i className="bi bi-cloud-upload-fill"></i> */ }
              </div>
              <li>
                <div className="det w100">
                  <h5>
                    { userDetails.first_name } { userDetails.last_name }
                  </h5>
                  <h6>{ userDetails.email }</h6>
                  <p>{ userDetails.uniquecode }</p>
                </div>

                { userDetails.email_verified == "yes" || e_verify == 'yes' ? (
                  <h6>
                    <i style={ { fontSize: '20px', color: 'green' } } className="bi bi-shield-fill-check"></i>
                  </h6>
                ) : otpText != '' ? (

                  <input className="otpDashboard" placeholder="otp" min={ 0 } type="number" value={ otp } onChange={ ( e ) => { setOtp( e.target.value ); VerifyOtp( e.target.value, userDetails.email ) } } />
                ) : (

                  <button onClick={ ( e ) => { SendOtpViaMail( userDetails.email, userDetails.phone ); } }>Verify</button>
                )
                }


              </li>
              <li>
                <div className="det det2 w100">
                  <h6>Account Type</h6>
                  <p>{ userDetails.account_type } Membership</p>
                </div>


                {
                  userDetails.account_type != 'Free' ? (
                    <h6>
                      <i style={ { fontSize: '20px', color: 'green' } } className="bi bi-patch-check-fill"></i>
                    </h6>
                  ) : (
                    <button
                      style={ {
                        background: "#DAA520 !important",
                        border: "1px solid #000",
                      } }

                    >
                      <Link to='/plan' style={ { color: '#ffffff' } }>Upgrade</Link>
                    </button>
                  )
                }
              </li>

            </div>

            <div className="profile ad_p fcc-display w100">
              <Link to='/plan'><img src="/img/premium_plan.jpg" alt="Plan" /></Link>
            </div>
            <div className="profile ad_p fcc-display w100">
              <img src="/img/contact_call.jpg" alt="" />
            </div>
          </div>

          <div className="cards_recent_added">
            {/* cards  */ }
            <div className="dash_cards w100 fcs-display">
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ visterData.length }</h3>
                  <p>Recent Visiters</p>
                </div>
                <div className="img_icon">
                  <img src="icon/recent_visiter.png" alt="Recent Visiters" />
                </div>
                {/* <div className="icon"><i className="bi bi-clock-history"></i></div> */ }
              </div>
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ getCountByActivityType( "shortlisted" ) }</h3>
                  <p>Shortlisted Profiles</p>
                </div>
                <div className="img_icon">
                  <img src="icon/shortList.png" alt="Shortlisted Profiles" />
                </div>
                {/* <div className="icon"><i className="bi bi-person-plus-fill"></i></div> */ }
              </div>
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ getCountByActivityType( "accept_invite" ) }</h3>
                  <p>Accepted Invition</p>
                </div>
                <div className="img_icon">
                  <img
                    src="icon/accept_invitation.png"
                    alt="Accepted Invition"
                  />
                </div>
                {/* <div className="icon"><i className="bi bi-check-circle-fill"></i></div> */ }
              </div>
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ getCountByActivityType( "pendig_invite" ) }</h3>
                  <p>Pending Invition</p>
                </div>
                <div className="img_icon">
                  <img
                    src="icon/pending_invitaion.png"
                    alt="Pending Invition"
                  />
                </div>
                {/* <div className="icon"><i className="bi bi-hourglass-split"></i></div> */ }
              </div>
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ getCountByActivityType( "contact_viewed" ) }</h3>
                  <p>Contact Viewed</p>
                </div>
                <div className="img_icon">
                  <img src="icon/contact_viewed.png" alt="Contact Viewed" />
                </div>
                {/* <div className="icon"><i className="bi bi-person-vcard-fill"></i></div> */ }
              </div>
              <div className="dash_card fcs-display">
                <div className="content">
                  <h3>{ getCountByActivityType( "chat_invite" ) }</h3>
                  <p>Chats Invited</p>
                </div>
                <div className="img_icon">
                  <img src="icon/chat.png" alt="Chats Invited" />
                </div>
                {/* <div className="icon"><i className="bi bi-chat-dots-fill"></i></div> */ }
              </div>
            </div>

            {/* Recent Visiter  */ }

            <div className="recent">
              <div className="head fcs-display w100">
                <h3>Recent Visitors</h3>
                <div className="scroll_btn">
                  <i
                    className="bi bi-caret-left-fill"
                    onClick={ () =>
                    {
                      document.getElementsByClassName(
                        "primumium_scetion"
                      )[ 0 ].scrollLeft -=
                        document.getElementsByClassName( "card-container" )[ 0 ]
                          .clientWidth + 15;
                    } }
                  ></i>
                  <i
                    className="bi bi-caret-right-fill"
                    onClick={ () =>
                    {
                      document.getElementsByClassName(
                        "primumium_scetion"
                      )[ 0 ].scrollLeft +=
                        document.getElementsByClassName( "card-container" )[ 0 ]
                          .clientWidth + 15;
                    } }
                  ></i>
                </div>
              </div>
              <div className="recent_cards primumium_scetion fcs-display">
                { visterData.length > 0 ? (
                  visterData.map( ( el, i ) => (

                    <div className="card-container" key={ i }>
                      <span className="pro">{ el.account_type }</span>
                      <img
                        className="round"
                        src={ el.profile_pic }
                        alt="user"
                        onError={ ( e ) => { e.target.onerror = null; e.target.src = 'https://svgsilh.com/png-512/659651.png'; } }
                      />
                      <h3>{ el.first_name + ' ' + el.last_name }</h3>
                      <h6>{ el.country }</h6>
                      <p>
                        { el.designation }
                      </p>
                      <Link to={ `/user/${ el.uniquecode }` }>View</Link>
                      <div className="recent_buttons">
                        <i className="bi bi-person-check-fill"></i>
                      </div>
                    </div>
                  ) )
                ) : (
                  <p>No recent visitors found.</p>
                ) }
              </div>
            </div>

            {/*  added  */ }

            <div className="recent">
              <div className="head fcs-display w100">
                <h3>Recently Added</h3>
                <div className="scroll_btn">
                  <i
                    className="bi bi-caret-left-fill"
                    onClick={ () =>
                    {
                      document.getElementsByClassName(
                        "recent_add"
                      )[ 0 ].scrollLeft -=
                        document.getElementsByClassName( "card-container" )[ 0 ]
                          .clientWidth + 15;
                    } }
                  ></i>
                  <i
                    className="bi bi-caret-right-fill"
                    onClick={ () =>
                    {
                      document.getElementsByClassName(
                        "recent_add"
                      )[ 0 ].scrollLeft +=
                        document.getElementsByClassName( "card-container" )[ 0 ]
                          .clientWidth + 15;
                    } }
                  ></i>
                </div>
              </div>
              <div className="recent_cards recent_add fcs-display">
                { RecentlyAdded.length > 0 ? (
                  RecentlyAdded.map( ( el, i ) => (

                    <div className="card-container" key={ el.id || i }>
                      <span className="pro">{ el.account_type }</span>
                      <img
                        className="round"
                        src={ el.profile_pic }
                        alt="user"
                        onError={ ( e ) => { e.target.onerror = null; e.target.src = 'https://svgsilh.com/png-512/659651.png'; } }
                      />
                      <h3>{ el.first_name + ' ' + el.last_name }</h3>
                      <h6>{ el.country }</h6>
                      <p>
                        { el.designation }
                      </p>
                      <div className="recent_buttons">
                        <i className="bi bi-person-check-fill"></i>
                      </div>
                    </div>
                  ) )
                ) : (
                  <p>No recent visitors found.</p>
                ) }
              </div>
            </div>
            {/*  added  */ }

            {/* <div className="recent" style={{ marginTop: "-32px" }}>
              <div className="head fcs-display w100">
                <h3>Near me</h3>
                <div className="scroll_btn">
                  <i
                    className="bi bi-caret-left-fill"
                    onClick={() => {
                      document.getElementsByClassName(
                        "near_me"
                      )[0].scrollLeft -=
                        document.getElementsByClassName("card-container")[0]
                          .clientWidth + 15;
                    }}
                  ></i>
                  <i
                    className="bi bi-caret-right-fill"
                    onClick={() => {
                      document.getElementsByClassName(
                        "near_me"
                      )[0].scrollLeft +=
                        document.getElementsByClassName("card-container")[0]
                          .clientWidth + 15;
                    }}
                  ></i>
                </div>
              </div>
              <div className="recent_cards near_me fcs-display">
              {NearMe.length > 0 ? (
                  NearMe.map((el, i) => (

                    <div className="card-container" key={el.id || i}>
                    <span className="pro">{el.account_type}</span>
                    <img
                      className="round"
                      src={el.profile_pic}
                      alt="user"
                    />
                    <h3>{el.first_name +' '+ el.last_name}</h3>
                    <h6>{el.country}</h6>
                    <p>
                     {el.designation}
                    </p>
                    <div className="recent_buttons">
                      <i className="bi bi-person-check-fill"></i>
                    </div>
                  </div>
                  ))
                ) : (
                  <p>No recent visitors found.</p>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
